import React from 'react';
import './Logo.css';

function Logo({ scale = 1, colour = 'white' }) {
  const baseSizeH1 = 27; 
  const baseSizeH3 = 6;  
  const baseMarginBottom = -2; 

  const styleH1 = {
    fontSize: `${baseSizeH1 * scale}px`,
    marginBottom: `${baseMarginBottom * scale}px`,
    color: colour
  };

  const styleH3 = {
    fontSize: `${baseSizeH3 * scale}px`,
    color: colour
  };

  return (
    <div className="Logo">
        <h1 style={styleH1}>AAAT</h1>
        <h3 className='Logo-Subtitle' style={styleH3}>HEATING AND GAS SERVICES</h3>
    </div>
  );
}

export default Logo;
