import React from 'react';
import './OurMissionSection.css';
import { Primary,Secondary,Tertiary } from '../Constants/Colours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'



function OurMissionSection() {
  return (
    <div style={{backgroundColor:Tertiary}} className="Our-Mission-Section">
        <div>
        <div className='Mission-Text-Container'>
            <h1>Our Mission</h1>
            <h2>To ensure the highest standards of safety and efficiency in heating and gas solutions, enhancing comfort and sustainability for each and every customer.</h2>
        </div>
        <div className='Core-Values-Container'>
            <h1>Core Values</h1>
            <div>
            <div>
            <h2><FontAwesomeIcon color={Primary} icon={faCircleCheck} /> Ensuring all operations adhere to strict safety protocols.</h2>
            
            <h2><FontAwesomeIcon color={Primary} icon={faCircleCheck} /> Commitment to honesty and transparency in all our dealings.</h2>
            
            </div>
            <div>
            <h2><FontAwesomeIcon color={Primary} icon={faCircleCheck} /> Prioritizing the needs and expectations of our clients.</h2>
            <h2><FontAwesomeIcon color={Primary} icon={faCircleCheck} /> Consistent delivery of high-standard services.</h2>
            </div>  
            </div> 
        </div>
        </div>
    </div>
  );
}

export default OurMissionSection;
