import React from 'react';
import './HeroSection.css';
import { Primary,Secondary } from '../Constants/Colours';
import {useNavigate} from 'react-router-dom';


function HeroSection() {
  const navigate = useNavigate();

    function contactAAAT() {
        navigate('/contact')
    }
  return (
    <div style={{backgroundColor:Primary}} className="Services-Hero-Section">
      <div style={{borderColor:Secondary}} className="Background-Circle"></div>
      <div className='Services-Hero-Text-Container'>
        <h1>Services We Offer</h1>
        <h2>AAAT offers a wide range of gas and heating services right from repairs to replacements in domestic setups</h2>
        <h2 style={{fontStyle:'italic',opacity:0.55,fontSize:"15px"}}>This list of services is not exhaustive. For more information or specific inquiries, please <span onClick={contactAAAT}  style={{textDecoration:'underline',cursor:'pointer'}}>contact AAAT directly.</span></h2>
      </div>
    </div>
  );
}

export default HeroSection;
