import React from 'react';
import './AboutSection.css';
import { Primary,Secondary,Tertiary } from '../Constants/Colours';
import AboutUsImage from '../Images/AboutPageImages/AboutUsImage.jpg'


function AboutSection() {
  return (
    <div style={{backgroundColor:Tertiary}} className="About-Us-Section">
        <div>
            <img src={AboutUsImage}/>
            <div style={{backgroundColor:Primary}}>
                <div style={{borderColor:Secondary}} className="Background-Circle"></div>
                <div>
                <h1>AAAT Provides Top Tier Heating and Gas Solutions</h1>
                <h2>AAAT's commitment to delivering top-tier service is supported by a strong network of industry connections, ensuring every project meets the highest standards of quality and safety. With extensive experience and a dedication to excellence, AAAT provides reliable and efficient solutions tailored to meet the unique needs of each customer.</h2>
                </div>
            </div>
        </div>
    </div>
  );
}

export default AboutSection;
