import React from 'react';
import './WhySection.css';
import { Primary,Secondary,Tertiary } from '../Constants/Colours';
import WhyUsImage from '../Images/AboutPageImages/WhyUsImage.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'


function WhySection() {
  return (
    <div style={{backgroundColor:Tertiary}} className="Why-Us-Section">
        <div>
            <div style={{backgroundColor:Primary}}>
                <div style={{borderColor:Secondary}} className="Background-Circle"></div>
                <div>
                <h1>Why Choose Us?</h1>
                <h2>At AAAT, appreciation is always shown by going the extra mile for you, ensuring every service is seamless and top-notch.</h2>
                <h3><FontAwesomeIcon color='white' icon={faCircleCheck} /> Decades of industry experience in the residential sector.</h3>
                <h3><FontAwesomeIcon color='white' icon={faCircleCheck} /> We deliver high-quality outcomes with every project we take on.</h3>
                <h3><FontAwesomeIcon color='white' icon={faCircleCheck} /> Streamlined processes for timely and successful service delivery</h3>
                <h3><FontAwesomeIcon color="white" icon={faCircleCheck}/> G3 Certified</h3>
                </div> 
            </div>
            <img src={WhyUsImage}/>
            
        </div>
    </div>
  );
}

export default WhySection;
