import React from 'react';
import logo from './logo.svg';
import HeroSection from '../AboutPageComponents/HeroSection';
import AboutSection from '../AboutPageComponents/AboutSection';
import OurMissionSection from '../AboutPageComponents/OurMissionSection';
import WhySection from '../AboutPageComponents/WhySection'
import Reviews from '../AboutPageComponents/Reviews';
import HereToHelp from '../GlobalComponents/HereToHelp';
import BannerWidget from '../AboutPageComponents/BannerWidget';


function About() {
  return (
    <div className="About">
        <title>About - AAAT Heating and Gas Services</title>
        <HeroSection/>
        <AboutSection/>
        <OurMissionSection/>
        <WhySection/>
        <BannerWidget/>
        <HereToHelp/>
    </div>
  );
}

export default About;