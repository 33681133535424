import React from 'react';
import './HeroSection.css';
import { Primary,Secondary } from '../Constants/Colours';


function HeroSection() {
  return (
    <div style={{backgroundColor:Primary}} className="Contact-Hero-Section">
      <div style={{borderColor:Secondary}} className="Background-Circle"></div>
      <div className='Contact-Hero-Text-Container'>
        <h1>Contact Us</h1>
        <h2>Contact AAAT today and you can expect to hear back from a representative within a working day</h2>
      </div>
    </div>
  );
}

export default HeroSection;
