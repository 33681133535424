import React from 'react';
import './HeroSection.css';
import { Primary,Secondary } from '../Constants/Colours';


function HeroSection() {
  return (
    <div style={{backgroundColor:Primary}} className="About-Hero-Section">
      <div style={{borderColor:Secondary}} className="Background-Circle"></div>
      <div className='About-Hero-Text-Container'>
        <h1>About Us</h1>
        <h2>AAAT offers two decades of expertise in residential projects. <br /> Delivering quality, efficiency, and reliability with each tailored solution</h2>

      </div>
    </div>
  );
}

export default HeroSection;
