import React, { useEffect } from 'react';

const BannerWidget: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.reviewability.com/js/widgetAdv.min.js';
    script.async = true;

    script.onerror = () => {
      console.warn("Failed to load the Reviewability script.");
    };

    const div = document.getElementById('banner-widget');
    if (div) div.appendChild(script);

    return () => {
      if (div && div.contains(script)) {
        div.removeChild(script);
      }
    };
  }, []);

  return (
    <section style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
    <div id="banner-widget" data-bid="141181" data-url="https://app.revu.cloud" data-aid="17566">
      <script className="json-ld-content" type="application/ld+json"></script>
    </div>
    </section>
  );
};

export default BannerWidget;
