import React, {useState,useEffect} from 'react';
import './Reviews.css';
import { Tertiary } from '../Constants/Colours';
import Image1 from '../Images/HomePageImages/HowCanWeHelpImage1.jpg'
import Image2 from '../Images/HomePageImages/HowCanWeHelp2.jpg'
import Image3 from '../Images/HomePageImages/HowCanWeHelp3.jpg'
import WhiteButton from '../GlobalComponents/WhiteButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'




function Reviews() {
    const [width,setWidth] = useState(0)

    useEffect(() => {
        const handleResize =  () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize',handleResize)
    
        handleResize()
    
        return () => {
            window.removeEventListener('resize',handleResize);
        }
    
        }, [])
  return (
    <div style={{backgroundColor:Tertiary}} className="What-Our-Customers-Say">
        <h1>What our Customers Say</h1>
        <div className='Reviews-Container'>
            
            <div className='Review'>
                <div className='Star-Container'>
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                </div>
                <h2>Low pressure in boiler</h2>
                <div>
                    <h1>/ / </h1>
                    <h4>Kuba, Deptford, London</h4>
                </div>
                <h3>Very quick to resolve my boiler issue. Very professional and friendly service</h3>
                
                

            </div>
            <div className='Review'>
            <div className='Star-Container'>
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                </div>
                
                <h2>Gas Safety Certificate</h2>
                <div>
                    <h1>/ / </h1>
                    <h4>George, Clapham, London</h4>
                </div>
                <h3>Took care of this gas certificate promptly and for a reasonable quote and communicated well, producing both the certificate and follow up advice very quickly and efficiently. Recommended</h3>
                

            </div>

            <div className='Review'>
            <div className='Star-Container'>
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                </div>
                
                <h2>Hive heating installation</h2>
                <div>
                    <h1>/ / </h1>
                    <h4>Rebecca, South Norwood, London</h4>
                </div>
                <h3>Great price, flexible timings, job well done!</h3>
                
                
            </div>
        </div>
        <div className='Reviews-Container'>
            
            <div className='Review'>
                <div className='Star-Container'>
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                </div>
                <h2>No Hot Water - Unvented Water Cylinder</h2>
                <div>
                    <h1>/ / </h1>
                    <h4>Jessica, Bromley by Bow, London</h4>
                </div>
                <h3>Great service, contacted us promptly to visit and assess job then we were able to arrange a convenient time to get the job done. Really impressed, reasonable prices and would definitely highly recommend. Thank you</h3>
                
                

            </div>
            <div className='Review'>
            <div className='Star-Container'>
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                </div>
                
                <h2>Combi boiler Hot Water Problem</h2>
                <div>
                    <h1>/ / </h1>
                    <h4>James, New Cross, London</h4>
                </div>
                <h3>Diagnosed the problem with my combi-boiler very quickly and repaired the same day. Couldn't be better. Highly recommended.</h3>
                

            </div>

            <div className='Review'>
            <div className='Star-Container'>
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                <FontAwesomeIcon color='gold'  icon={faStar} />
                </div>
                
                <h2>Boiler Not Heating Water</h2>
                <div>
                    <h1>/ / </h1>
                    <h4>Nilsson, Southwark, London</h4>
                </div>
                <h3>Sorted out the problem and offered me two solutions with different prices. I liked that he was looking for an efficient solution for me at a better price.</h3>
                
                
            </div>
        </div>

        <a href="https://www.mybuilder.com/profile/aaat_heating/reviews?page=1"><WhiteButton height={45} width={180} text={'More Reviews'} arrow={true} darkMode={true}/></a>

     
    </div>
  );
}

export default Reviews;
