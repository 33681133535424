import React from 'react';
import './App.css';
import Navbar from './Components/GlobalComponents/Navbar';
import Footer from './Components/GlobalComponents/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Pages/Home'
import About from './Components/Pages/About'
import Services from './Components/Pages/Services';
import ContactUs from './Components/Pages/ContactUs';
import NotFound from './Components/Pages/NotFound';
import CookieConsent from './Components/GlobalComponents/CookieConsent';


function App() {
  return (
    <div className="App">
      <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/'  element={<Home/>} />
          <Route path='/about'  element={<About/>} />
          <Route path='/services'  element={<Services/>} />
          <Route path='/contact'  element={<ContactUs/>} />
          <Route path='*'  element={<NotFound/>} />
        </Routes>
        <CookieConsent />
        <Footer />
    </Router>
      </>
    </div>
  );
}

export default App;
