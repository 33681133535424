import React from 'react';
import {useNavigate} from 'react-router-dom';
import './HeroSection.css';
import { Primary, Secondary } from '../Constants/Colours';
import HeroImage1 from '../Images/HomePageImages/HeroImage4.jpg'
import WhiteButton from '../GlobalComponents/WhiteButton'



function HeroSection() {
  const navigate = useNavigate();

  function ContactPage() {
    navigate('/contact',{state:{form : true}})
  }

  return (
    <div style={{backgroundColor:Primary}} className="Hero-Section">
        <div style={{borderColor:Secondary}} className="Background-Circle"></div>
        <div className='Hero-Info-Container'>
            <div className='Hero-Text-Container'>
                <h1>Excellent Gas and Heating Services in London</h1>
                <h2>AAAT provides premier gas and heating solutions including boiler installation, central heating repairs, gas safety checks, and much more!</h2>
                <WhiteButton onClick={ContactPage} height={45} width={180} text={'Send an Enquiry'} arrow={true}/>
            </div>
        </div>
        <div className='Hero-Image-Container'>
        <img src={HeroImage1}/>
        </div>
    </div>
  );
}

export default HeroSection;
