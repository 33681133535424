import React from 'react';
import {useNavigate} from 'react-router-dom';
import './HowCanWeHelp.css';
import { Tertiary } from '../Constants/Colours';
import Image1 from '../Images/ServicesPageImages/HowCanWeHelpImage1.jpg'
import Image2 from '../Images/ServicesPageImages/HowCanWeHelp2.jpg'
import Image3 from '../Images/ServicesPageImages/HowCanWeHelp3.jpg'
import Image4 from '../Images/ServicesPageImages/HowCanWeHelpImage4.jpg'
import Image5 from '../Images/ServicesPageImages/HowCanWeHelpImage5.jpg'
import Image6 from '../Images/ServicesPageImages/HowCanWeHelpImage6.jpg'
import Image7 from '../Images/ServicesPageImages/HowCanWeHelpImage7.jpg'
import Image8 from '../Images/ServicesPageImages/HowCanWeHelpImage8.jpg'
import Image9 from '../Images/ServicesPageImages/HowCanWeHelpImage9.jpg'
import Image10 from '../Images/ServicesPageImages/HowCanWeHelpImage18.jpg'
import Image11 from '../Images/ServicesPageImages/HowCanWeHelpImage19.jpg'
import Image12 from '../Images/ServicesPageImages/HowCanWeHelpImage21.jpg'
import Image13 from '../Images/ServicesPageImages/HowCanWeHelpImage21.png'
import Image14 from '../Images/ServicesPageImages/HowCanWeHelpImage20.jpg'
import Image15 from '../Images/ServicesPageImages/HowCanWeHelpImage23.jpg'
import Image16 from '../Images/ServicesPageImages/HowCanWeHelpImage24.jpg'


import WhiteButton from '../GlobalComponents/WhiteButton'




function HowCanWeHelp() {
    const navigate = useNavigate();

    function selectService(service : any) {
        navigate('/contact',{state: {Service:service}})
    }



  return (
    <div style={{backgroundColor:Tertiary}} className="Services-How-Can-We-Help">
        <div>
        <h1>How can we help you?</h1>
        <div className='All-Services-Container'>
        <div className='Services-Container'>
            
            <div onClick={() => selectService('Landlord/Maintenance Certificates')} className='Service'>
                <div>
                <img src={Image2}/>
                </div>
                <h2>Landlord/Maintenance Certificates</h2>
                <h3>Whether issuing compliance certificates or conducting routine inspections, AAAT ensures that all standards are met with precision, providing you with reliable and thorough certification services.</h3>

            </div>
            <div onClick={() => selectService('Boiler Installation Services')} className='Service'>
            <div>
                <img src={Image1}/>
                </div>
                <h2>Boiler Installation Services</h2>
                <h3>AAAT excels in installing new boilers and modernizing existing heating systems. AAAT offer customized installation solutions designed to maximize efficiency and ensure lasting comfort in your home.</h3>

            </div>

            <div onClick={() => selectService('Boiler Repair')} className='Service'>
                <div>
                    <img src={Image3}/>
                </div>
                <h2>Boiler Repair</h2>
                <h3>Whether it's servicing a malfunctioning boiler or replacing parts, AAAT has the expertise to diagnose the issue accurately and provide you with the most effective solution</h3>
                
            </div>
        </div>
        <div className='Services-Container'>
            
            <div onClick={() => selectService('Smart Home System Installation')} className='Service'>
                <div>
                <img src={Image4}/>
                </div>
                <h2>Smart Home System Installation</h2>
                <h3>AAAT are experts in installing a variety of smart home systems, including thermostats and home automation solutions from brands like Hive and others. Delivering seamless integration with your existing home systems, providing you with convenience and enhanced control over your heating and energy usage.</h3>

            </div>
            <div onClick={() => selectService('Domestic Power Flush Services')} className='Service'>
            <div>
                <img src={Image9}/>
                </div>
                <h2>Domestic Power Flush Services</h2>
                <h3>AAAT offer detailed power flush solutions tailored for residential settings. From routine cleaning to full system flushes, AAAT is equipped to handle all aspects of your home heating system's maintenance. Ensuring your home operates efficiently with optimal system performance and minimal disruption.</h3>

            </div>

            <div onClick={() => selectService('Emergency Heating Services')} className='Service'>
                <div>
                    <img src={Image5}/>
                </div>
                <h2>Emergency Heating Services</h2>
                <h3>AAAT's emergency services are designed to address urgent heating issues swiftly and effectively. Whether it's a broken heater in the dead of winter or a sudden boiler shutdown, AAAT is ready to restore your comfort and safety with prompt, reliable repairs.</h3>
                
            </div>
        </div>
        <div className='Services-Container'>
            
            <div onClick={() => selectService('Radiator Installation Services')} className='Service'>
                <div>
                <img src={Image8}/>
                </div>
                <h2>Radiator Installation Services</h2>
                <h3>AAAT provides professional installation services for residential radiator systems. Whether you're upgrading your home's heating efficiency or installing a new system, AAAT has the expertise to deliver precise and reliable radiator setups.</h3>

            </div>

            <div onClick={() => selectService('Gas Fire Service')} className='Service'>
                <div>
                <img src={Image10}/>
                </div>
                <h2>Gas Fire Service</h2>
                <h3>Ensure the safety and efficiency of your living space with our specialized gas fireplace services. At AAAT, we focus on providing expert maintenance and precise repairs for all types of residential gas fireplaces. Keep your heating appliances performing at their best with our professional servicing, designed to maintain optimal functionality and safety.</h3>

            </div>

            <div onClick={() => selectService('Home Interface Unit Repair')} className='Service'>
                <div>
                <img src={Image11}/>
                </div>
                <h2>Home Interface Unit Repair</h2>
                <h3>AAAT offers professional repair services for home interface units, designed to restore and enhance the technological functionality within residential settings. Our services focus on precise and efficient troubleshooting and repair of advanced home interfaces, supporting improved connectivity and control.</h3>

            </div>
        </div>
        <div className='Services-Container'>
            
            <div onClick={() => selectService('Unvented Cylinder Installation')} className='Service'>
                <div>
                <img src={Image13}/>
                </div>
                <h2>Unvented Cylinder Installation</h2>
                <h3>AAAT provides professional unvented cylinder installation, carried out by a G3 Certified engineer. Specializing in the setup of high-efficiency unvented water systems, our service ensures compliance with safety standards and optimal performance in residential environments.</h3>

            </div>

            <div onClick={() => selectService('Home Interface Unit Installation')} className='Service'>
                <div>
                <img src={Image12}/>
                </div>
                <h2>Home Interface Unit Installation</h2>
                <h3>AAAT offers professional installation of home interface units, designed to enhance the technological integration within residential settings with focus on precise and efficient setup of advanced home interfaces, supporting improved connectivity and control.</h3>

            </div>

            <div onClick={() => selectService('Normal Cylinder Repair')} className='Service'>
                <div>
                <img src={Image14}/>
                </div>
                <h2>Normal Cylinder Repair</h2>
                <h3>AAAT provides professional repair services for residential cylinders, ensuring efficient and reliable restoration of your system's functionality. AAAT specializes in diagnosing and fixing issues with standard water cylinders, maintaining high standards of quality and precision.</h3>

            </div>
        </div>
        <div className='Services-Container'>
            
            <div onClick={() => selectService('Unvented Cylinder Repair')} className='Service'>
                <div>
                <img src={Image15}/>
                </div>
                <h2>Unvented Cylinder Repair</h2>
                <h3>AAAT offers specialized repair services for unvented cylinders, ensuring these systems function efficiently and safely within residential settings. AAAT are experts in diagnosing and resolving issues, maintaining the integrity and performance of your unvented water system. </h3>

            </div>

            <div onClick={() => selectService('Normal Cylinder Installation')} className='Service'>
                <div>
                <img src={Image16}/>
                </div>
                <h2>Normal Cylinder Installation</h2>
                <h3>AAAT provides professional installation services for standard water cylinders, tailored to meet the needs of residential environments. AAAT ensures a seamless and efficient setup, enhancing the reliability and performance of your home water system.</h3>

            </div>
        </div>
        </div>
        </div>

     
    </div>
  );
}

export default HowCanWeHelp;
