import React, {useState, useEffect} from 'react';
import './Navbar.css';
import Logo from './Logo';
import WhiteButton from './WhiteButton';
import { Primary, Tertiary } from '../Constants/Colours';
import {MobPhoneNum, MobPhoneNumNoSpace, PhoneNum,PhoneNumNoSpace} from '../Constants/Phone'
import { useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
    const navigate = useNavigate()
    const location = useLocation()
    const [HomeOpacity,setHomeOpacity] = useState(1)
    const [AboutOpacity,setAboutOpacity] = useState(0.75)
    const [ServicesOpacity,setServicesOpacity] = useState(0.75)
    const [ContactOpacity,setContactOpacity] = useState(0.75)
    const [width,setWidth] = useState(0)
    const [menuOpen,setMenuOpen] = useState(false)
    const backgroundstyle = {
    backgroundColor:Primary,
    };



    

    function ContactPage() {
        navigate('/contact',{state:{form : true}})
    }

    function SelectPage(Page : String) {
        setHomeOpacity(Page === 'Home' ? 1 : 0.75);
        setAboutOpacity(Page === 'About' ? 1 : 0.75);
        setServicesOpacity(Page === 'Services' ? 1 : 0.75);
        setContactOpacity(Page === 'Contact' ? 1 : 0.75);

        switch (Page) {
            case 'Home':
                navigate('/');
                break;
            case 'About':
                navigate('/about');
                break;
            case 'Services':
                navigate('/services');  // Ensure you have a route set up for this
                break;
            case 'Contact':
                navigate('/contact');  // Ensure you have a route set up for this
                break;
            default:
                break;
        }
    }

    const ToggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
    const handleResize =  () => {
        setWidth(window.innerWidth)
    }
    window.addEventListener('resize',handleResize)

    handleResize()

    return () => {
        window.removeEventListener('resize',handleResize);
    }

    }, [])

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setHomeOpacity(1);
                setAboutOpacity(0.75);
                setServicesOpacity(0.75);
                setContactOpacity(0.75);
                break;
            case '/about':
                setHomeOpacity(0.75);
                setAboutOpacity(1);
                setServicesOpacity(0.75);
                setContactOpacity(0.75);
                break;
            case '/services':
                setHomeOpacity(0.75);
                setAboutOpacity(0.75);
                setServicesOpacity(1);
                setContactOpacity(0.75);
                break;
            case '/contact':
                setHomeOpacity(0.75);
                setAboutOpacity(0.75);
                setServicesOpacity(0.75);
                setContactOpacity(1);
                break;
            default:
                // Set all to less active state if the path is not recognized
                setHomeOpacity(0.75);
                setAboutOpacity(0.75);
                setServicesOpacity(0.75);
                setContactOpacity(0.75);
                break;
        }
    }, [location]);


    if (width < 1024) {
        return (
            <div style={backgroundstyle} className="Navbar">
        
        <div className='Menu-Container'>
            <div className='First-Half'>
                {width < 430 ? <Logo scale={1.2}/> :<Logo scale={1.6}/>}
            </div>

            
            <div className ='Button-Container'>
                
                {width < 430 ?<WhiteButton onClick={ToggleMenu} height={40} width={40} text={<FontAwesomeIcon size='1x' icon={menuOpen ? faX : faBars}/>}/> :<WhiteButton onClick={ToggleMenu} height={60} width={60} text={<FontAwesomeIcon size='2x' icon={faBars}/>}/>}
            </div>
            
        </div>
        {menuOpen && (
            <div style={{backgroundColor:Tertiary}} className='Mobile-Menu-Container'>
                <div className='Mobile-Menu'>
                    <h1 onClick={() => SelectPage('Home')} style={{color:HomeOpacity == 1 ? Primary : '#484c50'}}>Home</h1>
                    <h1 onClick={() => SelectPage('About')} style={{color:AboutOpacity == 1 ? Primary : '#484c50'}}>About</h1>
                    <h1 onClick={() => SelectPage('Services')} style={{color:ServicesOpacity == 1 ? Primary : '#484c50'}}>Services</h1>
                    <h1 onClick={() => SelectPage('Contact')} style={{color:ContactOpacity == 1 ? Primary : '#484c50'}}>Contact</h1>
                </div>

            </div>
        )
        }
    </div>

        )
    }




  return (
    <div style={backgroundstyle} className="Navbar">
        
        <div className='Menu-Container'>
            <div className='First-Half'>
                {width < 1200 ? <Logo scale={1.2}/> : <Logo scale={1.4}/>}
                

                <div className ='Menu-Options'>
                    <div onClick={() => SelectPage('Home')} style={{opacity:HomeOpacity}}>
                    <h1>
                        Home
                    </h1>
                    </div>

                    <div  onClick={() => SelectPage('About')} style={{opacity:AboutOpacity}}>
                    <h1 >
                        About
                    </h1>
                    </div>

                    <div onClick={() => SelectPage('Services')} style={{opacity:ServicesOpacity}}>
                    <h1  >
                        Services
                    </h1>
                    </div>

                    <div onClick={() => SelectPage('Contact')} style={{opacity:ContactOpacity}}>
                    <h1  >
                        Contact
                    </h1>
                    </div>
                </div>
            </div>

            <div className ='Emergency'>
                <h1>
                    Emergency?
                </h1>
                <div className='Numbers'>
                <h1 style={{whiteSpace:'nowrap'}}>
                    Call AAAT:
                </h1>
                    <div>
                    <a style={{textDecoration:'none',color:'white',}} href={PhoneNumNoSpace}>
                    <h1 style={{fontSize:'15px',padding:'6px'}}>
                        {PhoneNum}
                    </h1>
                    </a>
                    <a style={{textDecoration:'none',color:'white',}} href={MobPhoneNumNoSpace}>
                    <h1 style={{fontSize:'15px',padding:'6px'}}>
                        {MobPhoneNum}
                    </h1>
                    </a>
                    </div>
                </div>
                
            </div>

            <div className ='Button-Container'>
                <WhiteButton onClick={ContactPage} height={45} width={120} text={'Get a Quote'}/>
            </div>
            
        </div>
    </div>
  );
}

export default Navbar;