import React, {useEffect} from 'react';
import './CookieConsent.css';
import { Primary, Secondary } from '../Constants/Colours';

const CookieConsent: React.FC = () => {

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (cookiesAccepted === 'false') {
            const dismiss = document.getElementById('consent');
            if (dismiss) {
                dismiss.style.display = 'none';
            }
        }
    }, []);


    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (cookiesAccepted === 'true') {
            const dismiss = document.getElementById('consent');
            if (dismiss) {
                dismiss.style.display = 'none';
            }
        }
    }, []);


    const handleAccept = () => { 
       window.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted'
      })
      const dismiss = document.getElementById('consent');
        if(dismiss) {
            dismiss.style.display = 'none';
        }
    localStorage.setItem('cookiesAccepted', 'true');
    }

    const handleReject = () => { 
        window.gtag('consent', 'update', {
         'ad_storage': 'denied',
         'ad_user_data': 'denied',
         'ad_personalization': 'denied',
         'analytics_storage': 'denied'
       })
       const dismiss = document.getElementById('consent');
        if(dismiss) {
            dismiss.style.display = 'none';
        }
        localStorage.setItem('cookiesAccepted', 'false');
     }
    return (
        <div style={{borderTopColor:Secondary}} id='consent' className="cookie-consent">
            <div> 
                <h1>We use cookies to ensure that we give you the best experience on our website. By using our website, you consent to our use of cookies.</h1>
                <button onClick={handleAccept} style={{backgroundColor:Secondary}}>Got it!</button>
                <button onClick={handleReject} style={{backgroundColor:Primary}}>No thanks.</button>
            </div>
        </div>
    );
};

export default CookieConsent;