import React from 'react';
import logo from './logo.svg';
import HeroSection from '../HomePageComponents/HeroSection';
import HowCanWeHelp from '../HomePageComponents/HowCanWeHelp';
import Reviews from '../HomePageComponents/Reviews'
import HereToHelp from '../GlobalComponents/HereToHelp';


function Home() {
  return (
    <div className="Home">
      <title>Home - AAAT Heating and Gas Services</title>
        <HeroSection/>
        <HowCanWeHelp/>
        <Reviews/>
        <HereToHelp/>

      
    </div>
  );
}

export default Home;