import React from 'react';
import {useNavigate} from 'react-router-dom';
import './HowCanWeHelp.css';
import { Tertiary } from '../Constants/Colours';
import Image1 from '../Images/HomePageImages/HowCanWeHelpImage1.jpg'
import Image2 from '../Images/HomePageImages/HowCanWeHelp2.jpg'
import Image3 from '../Images/HomePageImages/HowCanWeHelp3.jpg'
import WhiteButton from '../GlobalComponents/WhiteButton'




function HowCanWeHelp() {
    const navigate = useNavigate();

    function selectService(service : any) {
        navigate('/contact',{state: {Service:service}})
    }

    function ServicePage() {
        navigate('/services')
        window.scrollTo(0,0)
    }
  return (
    <div style={{backgroundColor:Tertiary}} className="How-Can-We-Help">
        <h1>What can AAAT do for you?</h1>
        <div className='Services-Container'>
            
            <div onClick={() => selectService('Landlord/Maintenance Certificates')} className='Service'>
                <div>
                <img src={Image2}/>
                </div>
                <h2>Landlord/Maintenance Certificates</h2>
                <h3>Whether issuing compliance certificates or conducting routine inspections, AAAT ensures that all standards are met with precision, providing you with reliable and thorough certification services.</h3>

            </div>
            <div onClick={() => selectService('Boiler Installation Services')} className='Service'>
            <div>
                <img src={Image1}/>
                </div>
                <h2>Boiler Installation Services</h2>
                <h3>AAAT excels in installing new boilers and modernizing existing heating systems. We offer customized installation solutions designed to maximize efficiency and ensure lasting comfort in your home.</h3>

            </div>

            <div onClick={() => selectService('Boiler Repair')}  className='Service'>
                <div>
                    <img src={Image3}/>
                </div>
                <h2>Boiler Repair</h2>
                <h3>Whether it's servicing a malfunctioning boiler or replacing parts, AAAT has the expertise to diagnose the issue accurately and provide you with the most effective solution</h3>
                
            </div>
        </div>

        <WhiteButton onClick={ServicePage} height={45} width={180} text={'See All Services'} arrow={true} darkMode={true}/>

     
    </div>
  );
}

export default HowCanWeHelp;
