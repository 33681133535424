import React,{useState,useEffect} from 'react';
import './Footer.css';
import { Primary,Tertiary } from '../Constants/Colours';
import GSR from '../Images/HomePageImages/GasSafeRegister.png'
import Logo from './Logo';
import {MobPhoneNum, MobPhoneNumNoSpace, PhoneNum,PhoneNumNoSpace} from '../Constants/Phone'

function Footer() {
  const [width,setWidth] = useState(0)
useEffect(()=> {
  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  window.addEventListener('resize',handleResize)

  handleResize()

  return () => {
      window.removeEventListener('resize',handleResize)
  }

},[])

 if (width < 430) {
  return (

    <div style={{backgroundColor:Tertiary}} className="Footer">
      <div>
        <div>
        <div>
          <h1>AAAT Heating and Gas<br/> Services</h1>
          <div>
            <h2>
              Email
            </h2>
            <h5>
              info@aaatheatingand<br/>gas.co.uk
            </h5>
          </div>
          <div>
            <h2>
              Call Us
            </h2>
            <a style={{textDecoration:'none',color:Primary}} href={PhoneNumNoSpace}>
            <h5 style={{color:Primary}}>
            {PhoneNum}
            </h5>
            </a>
            <a style={{textDecoration:'none',color:Primary}} href={MobPhoneNumNoSpace}>
            <h5 style={{color:Primary}}>
              {MobPhoneNum}
            </h5>
            </a>
          </div>
          
          

        </div>
        <div>
          <h1>Business Hours</h1>
          <div>
            <h1>
            Monday : 7.00 – 19.00 <br />
            Tuesday : 7.00 – 19.00 <br />
            Wednesday : 7.00 – 19.00 <br />
            Thursday : 7.00 – 19.00 <br />
            Friday : 7.00 – 19.00 <br />
            Saturday : 7.00 – 19.00 <br />
            Sunday : 7.00 – 19.00 <br />
            </h1>
          </div>
          <div>
           <h4>Copyright &copy; 2024 AAAT Heating and Gas Services</h4>
           <h4>Website by Attention To Precison Websites</h4>
           </div>
          <img src={GSR}/>

        </div>
        </div>
      </div>
    </div>
  );

 }

  return (

    <div style={{backgroundColor:Tertiary}} className="Footer">
      <div>
        <div>
        <div>
          <h1>AAAT Heating and Gas<br/> Services</h1>
          <div>
            <h2>
              Email
            </h2>
            <h5>
              info@aaatheatingandgas.co.uk
            </h5>
          </div>
          <div>
            <h2>
              Call Us
            </h2>
            <a style={{textDecoration:'none',color:Primary}} href={PhoneNumNoSpace}>
            <h5 style={{color:Primary}}>
              {PhoneNum}
            </h5>
            </a>
            <a style={{textDecoration:'none',color:Primary}} href={MobPhoneNumNoSpace}>
            <h5 style={{color:Primary}}>
              {MobPhoneNum}
            </h5>
            </a>
          </div>
          <div>
           <h4>Copyright &copy; 2024 AAAT Heating and Gas Services</h4>
           <h4>Website by Attention To Precison Websites</h4>
           </div>
          

        </div>
        <div>
          <h1>Business Hours</h1>
          <div>
          <h1>
            Monday : 7.00 – 19.00 <br />
            Tuesday : 7.00 – 19.00 <br />
            Wednesday : 7.00 – 19.00 <br />
            Thursday : 7.00 – 19.00 <br />
            Friday : 7.00 – 19.00 <br />
            Saturday : 7.00 – 19.00 <br />
            Sunday : 7.00 – 19.00 <br />
            </h1>
          </div>
          <img src={GSR}/>

          

        </div>
        </div>
       
      </div>
    </div>
  );
}

export default Footer;