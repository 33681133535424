import React from 'react';
import './HereToHelp.css';
import { Primary, Secondary } from '../Constants/Colours';
import HereToHelpImage from '../Images/HomePageImages/HereToHelpImage1.jpg'
import WhiteButton from '../GlobalComponents/WhiteButton';
import {MobPhoneNum, MobPhoneNumNoSpace, PhoneNum,PhoneNumNoSpace} from '../Constants/Phone'




function HereToHelp() {
    
  return (
    <div style={{backgroundColor:Primary}} className="HereToHelp">
        <div style={{borderColor:Secondary}} className="Background-Circle"></div>
        <img src={HereToHelpImage}/>
        <div>
            <h1>Here to help! Call <br /> AAAT Today!</h1>
            <a href={PhoneNumNoSpace}><WhiteButton height={45} width={190} text={PhoneNum} phone={true}/></a>
            <a style={{marginTop:'5px'}} href={MobPhoneNumNoSpace}><WhiteButton height={45} width={190} text={MobPhoneNum} phone={true}/></a>
        </div>
    </div>
  );
}

export default HereToHelp;
