import React from 'react';
import logo from './logo.svg';
import HeroSection from '../ServicesPageComponents/HeroSection';
import HowCanWeHelp from '../ServicesPageComponents/HowCanWeHelp';
import HereToHelp from '../GlobalComponents/HereToHelp'


function Services() {
  return (
    <div className="Services-Page">
        <title>Services - AAAT Heating and Gas Services</title>
        <HeroSection/>
        <HowCanWeHelp/>
        <HereToHelp/>
    </div>
  );
}

export default Services;