import React from 'react';
import './WhiteButton.css';
import { Primary } from '../Constants/Colours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight,faPhone } from '@fortawesome/free-solid-svg-icons'



interface WhiteButtonProps {
    height?: number;  
    width?: number;   
    onClick?: () => void;
    text: any;
    arrow?: boolean;
    darkMode?:boolean;
    phone?:boolean;
    disabled?:boolean;
    fontSize?:number;
}


const WhiteButton: React.FC<WhiteButtonProps> = ({ height = 20, width = 50, onClick, text, arrow = false, darkMode = false, phone = false, disabled = false,fontSize = 15, }) => {

    const style = {
        height: `${height}px`,
        width: `${width}px`,
        color:darkMode ? 'white' : Primary,
        backgroundColor: darkMode ? Primary : 'white',
        opacity: disabled ? 0.5 : 1,
        fontSize:`${fontSize}px`
      };

  if (phone) {
    return (
    <button disabled={disabled} onClick={onClick} style={style} className="White-Button">
      <FontAwesomeIcon color={style.color} icon={faPhone} /> {text} 
    </button>
    )
  }
  
  if (arrow) {
    return (
      <button disabled={disabled} onClick={onClick} style={style} className="White-Button">
          {text} <FontAwesomeIcon color={style.color} icon={faArrowRight} />
      </button>
    );
  } 

  return (
    <button disabled={disabled} onClick={onClick} style={style} className="White-Button">
        {text}
    </button>
  );
}

export default WhiteButton;
